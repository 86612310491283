.App {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
}

html,
body {
  /* Height and width fallback for older browsers. */
  height: 100%;

  height: 100vh;

  margin: 0;
  padding: 0;
}

/* body:before {
  background: url("./assets/background.svg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
} */

:root {
  --tooltip-text-color: black;
  --tooltip-background-color: white;
  --tooltip-margin: 100px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 100px;
  width: 100px;
  /* border-radius: 50px; */

  margin-left: 15px;
  margin-right: 20px;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;

  color: black;
  background-color: #edf5e1;

  font-weight: 700;
  line-height: 1;
  z-index: 100;
  white-space: normal;

  width: fit-content;
  height: 20px;
  /* box-shadow: 1px 1px 3.5px 1px #8ee4af; */
  padding: 10px;
  border-radius: 4px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  /* bottom: calc(var(--tooltip-margin) * -1); */

  top: 28px;
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  bottom: 103%;
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* -------------------------- CSS RESET STARTS HERE  -------------------------- */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
